"use strict";
/**

@module accordTab
@name accordTab
@kind module
@version 1.0.1

@description 
Гибрид табов, аккордеона и плавного скрола. Требует специального CSS и HTML. 
На широких экранах ведет себя как переключатель табов, на узких - как аккордеон.

@property {string} rootTag - тег корневого элемента
@property {string} nameTag: тег элементов, содержащих названия табов, они же переключают аккордеон
@property {string} itemTag: список табов
@property {string} nameGroup - группы названий табов - когда переключателей больше, чем табов, например, 
    когда табы переключаются из нескольких мест
@property {string} toggleTag - элемент открытия/закрытия аккордеона
@property {number} [[accordTabSize=0]] - размер ширины с страницы в пикселах (без указаний единиц), 
    при превышении которого скрипт ведет себя как табы, 
    менее - как аккордеон (могут быть свернуты все пункты)
@property {boolean} [[scroll]] - наличие прокрутки к выбранному пункту(необязательный параметр)
@property {number} [[ofset=0]] -смещение прокрутки (необязательный параметр, по умолчанию 0)
@property {number} [[duration=50]] - длительность прокрутки (необязательный параметр, по умолчанию 50)


@example <caption>Подключение</caption>
let accordTab = require("./accordTab");

@example <caption>Использование</caption>
// accordTab
accordTab ({
    rootTag: ".js-tabs",
    nameTag: ".js-tab-name",    
    itemTag: ".js-tab-item",
    nameGroup: ".js-tab-name-group",
    accordTabSize: 1150,
    scroll: true,
    ofset: 0,
    duration: 50
});


*/

module.exports = function(option) {
    // проверка
    if (option.rootTag && option.nameTag && option.itemTag) {
        if (
            document.querySelector(option.rootTag) &&
            document.querySelector(option.nameTag) &&
            document.querySelector(option.itemTag)
        ) {
            run(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден корневой элемент " + option.rootTag);
            } else {
                if (!document.querySelector(option.nameTag)) {
                    console.error(
                        "не найден список названий табов " + option.nameTag
                    );
                }

                if (!document.querySelector(option.nameGroup)) {
                    console.error("не найден список табов " + option.nameGroup);
                }

                if (!document.querySelector(option.itemTag)) {
                    console.error("не найден список табов " + option.itemTag);
                }
            }
        }
    } else {
        console.error("табам не заданы необходимые параметры");
    }

    // запуск слайдера
    function run(option) {
        // console.log("Подключен accodrTab " + option.rootTag);
        var rootEl = document.querySelector(option.rootTag);
        var itemList = rootEl.querySelectorAll(option.itemTag);
        itemList = Array.from(itemList);

        var nameGroupList = rootEl.querySelectorAll(option.nameGroup);
        nameGroupList = Array.from(nameGroupList);

        var accordTabSize = option.accordTabSize || 0;
        var isActiveTab = false;
        var firstStart = true;

        let setInit = function() {
            // перебираем названия групп
            nameGroupList.forEach(function(group, j) {
                let nameList = group.querySelectorAll(option.nameTag);
                nameList = Array.from(nameList);

                // проверка на равенство табов и инициализация событий
                if (nameList.length == itemList.length) {
                    nameList.forEach(function(item, i) {
                        nameList[i].onclick = function() {
                            goTab(i);
                        };
                        // добавляем класс "собственному" названию таба
                        if (i == j) {
                            nameList[i].classList.add("is-own");
                        }
                    });
                } else {
                    console.error(
                        "количество названий табов " +
                        nameList.length +
                        " не равно количеству табов " +
                        itemList.length
                    );
                }
            });

            doActive();
        };

        // основной код
        let removeActive = function() {
            itemList.forEach(function(item) {
                item.classList.remove("is-active");
            });

            // перебираем названия групп
            nameGroupList.forEach(function(group) {
                let nameList = group.querySelectorAll(option.nameTag);
                nameList = Array.from(nameList);

                nameList.forEach(function(item, i) {
                    nameList[i].classList.remove("is-active");
                });
            });
        };

        let goTab = function(i) {
            // если это не десктоп, задана ширина десктопа и вкладка активна
            if (
                typeof accordTabSize === "number" &&
                window.innerWidth < accordTabSize &&
                itemList[i].classList.contains("is-active")
            ) {
                removeActive();
                isActiveTab = false;
            } else {
                // очищаем табы и названия
                removeActive();

                // активируем таб
                itemList[i].classList.add("is-active");

                // перебираем названия групп
                nameGroupList.forEach(function(group) {
                    let nameList = group.querySelectorAll(option.nameTag);
                    nameList = Array.from(nameList);
                    // активируем названия внутри каждой группы
                    nameList[i].classList.add("is-active");
                });


                // если не десктоп и не первая загрузка страницы, запускаем скрол
                if (
                    typeof accordTabSize === "number" &&
                    window.innerWidth < accordTabSize &&
                    !firstStart
                ) {
                    scroll(itemList[i]);
                }

                firstStart = false;
            }
        };

        let doActive = function() {
            // перебираем табы
            itemList.forEach(function(item, i, arr) {
                if (item.classList.contains("is-active")) {
                    isActiveTab = true;
                }
            });

            // если нет активных табов, делаем первый активным
            if (isActiveTab === false) {
                removeActive();
                goTab(0);
            }
        };

        // прокрутка
        let scroll = function(elem) {
            // смещение от верха
            let ofset = option.ofset || 0;
            // длительность скрола
            let duration = option.duration || 50;

            // начальная прокрутка страницы
            let startPageYOffset = window.pageYOffset;

            // положение элемента на странице по Y
            function getCoords(elem) {
                let box = elem.getBoundingClientRect();
                return {
                    top: box.top + pageYOffset,
                    left: box.left + pageXOffset
                };
            }

            let boxCoords = getCoords(elem);
            let elemYOffset = boxCoords.top;

            // Действия:

            // получаем разницу
            let difference = elemYOffset - startPageYOffset - ofset;

            // console.log("elemYOffset: " + elemYOffset);
            // console.log("startPageYOffset: " + startPageYOffset);
            // console.log("ofset: " + ofset);
            // console.log("difference: " + difference);

            // делим ее на интервалы
            let perTick = difference / duration;
            // задаем переменную для сохранения текущего положения прокрутки
            let currentPageYOffset = startPageYOffset;

            //  запускаем функцию
            scrollToUniversal(duration);

            // функция прокрутки (реккурсивная)
            function scrollToUniversal(duration) {
                if (duration < 1) return;

                currentPageYOffset = currentPageYOffset + perTick;
                window.scrollTo(0, currentPageYOffset);
                // console.log(currentPageYOffset);

                setTimeout(function() {
                    scrollToUniversal(duration - 1);
                }, 4);
            }
        };

        window.onresize = function() {
            if (
                typeof accordTabSize === "number" &&
                window.innerWidth > accordTabSize
            ) {
                doActive();
            }
        };

        setInit();
    }
};
