/*

  :: readMoreGroup - читать еще группу v. 1.5 ::

Принимает настройки:

rootTag - корень списка
itemTag - класс пунктов
moreBtnTag - кнопка открытия следующей группы
step - шаг выдачи (пунктов в группе) (10)
scroll - прокрутка страницы к началу следующего пункта (необязательный параметр)
animationClass - класс анимации для новых пунктов (необязательный параметр)
scroll - наличие прокрутки к выбранному пункту (необязательный параметр)
ofset - смещение прокрутки (необязательный параметр, по умолчанию 0)
duration - длительность прокрутки (необязательный параметр, по умолчанию 50)
allOpen - сделать все вкладки открытыми (необязательный параметр)
useDisplay - если true, элементы скрываются/появляются с помощью display:none|block,
             если нет - то только через класс "is-open" дополнительными стилями

Действие: по клику на moreBtn добавляет класс "is-open" step (штук) на item, 
первому открытому класс "is-new-open"

Подключение: 
let readMoreGroup = require("./readMoreGroup");

Настройки:
readMoreGroup({
  rootTag: ".js-readMoreGroup",
  itemTag: ".js-readMoreGroup-item",
  moreBtnTag: ".js-readMoreGroup-btn",
  step: 10,
  stepIndicator: ".js-readMoreGroup-step",
  delay: 0,
  animationClass: "is-review-appear",
  scroll: true,
  ofset: 0,
  duration: 50,
  allOpen: false,
  useDisplay: true
});

*/

module.exports = function(option) {
  // проверка
  if (option.rootTag && option.itemTag && option.moreBtnTag) {
    if (
      document.querySelector(option.rootTag) &&
      document.querySelector(option.itemTag) &&
      document.querySelector(option.moreBtnTag)
    ) {
      runReadMoreGroup(option);
    } else {
      if (!document.querySelector(option.rootTag)) {
        // console.log("не найден ReadMoreGroup");
      } else {
        // if (!document.querySelector(option.itemTag)) {
        //   console.error("не найден контейнер");
        // }
        if (!document.querySelector(option.moreBtnTag)) {
          console.error("не найдена кнопка открытия");
        }
      }
    }
  } else {
    console.error("не заданы параметры readMoreGroup");
  }

  // запуск
  function runReadMoreGroup() {
    // console.log("Подключен ReadMoreGroup " + option.rootTag);
    var rootEl = document.querySelector(option.rootTag);
    var itemList = rootEl.querySelectorAll(option.itemTag);
    itemList = Array.from(itemList);
    var moreBtn = rootEl.querySelector(option.moreBtnTag);
    var step = option.step;
    var currentStep = 0;
    var stepIndicator = rootEl.querySelector(option.stepIndicator) || undefined;
    var delay = option.delay || 100;
    var animationClass = option.animationClass || "";
    var useDisplay = option.useDisplayNone || true;

    moreBtn.style.display = "none";

    let addOpen = function() {
      removeOpen();
      currentStep = currentStep + step;

      var localDelay = 0;
      var newOpen;

      itemList.forEach(function(item, i) {
        // первому новому элементу назначаем класс и перематываем к нему
        if (i == currentStep - step) {
          newOpen = item;
        }
        // всем открытым добавляем класс открытия
        if (i < currentStep) {
          // при этом

          if (i < currentStep - step) {
            // уже открытым присваиваем класс немедленно
            item.classList.add("is-open");
            if (useDisplay) {
              item.style.display = "block";
            }
          } else {
            // новым открытым присваиваем класс с задержкой
            // console.log("нов i:", i, " currentStep:", currentStep, " step:", step);
            setTimeout(function() {
              if (useDisplay) {
                item.style.display = "block";
              }
              item.classList.add("is-open");
              item.classList.add(animationClass);
            }, localDelay);
            // увеличиваем задержку для каждого следующего нового пункта
            localDelay = localDelay + delay;
          }
        }
      });

      // делаем скрол, но не прокручиваем если это первый вывод элементов
      if (option.scroll && currentStep > step) {
        setTimeout(function() {
          scroll(newOpen);
        }, localDelay / (step - 1));
      }

      // убрать кнопку, если текущий шаг больше общего количества элементов
      if (currentStep >= itemList.length) {
        moreBtn.style.display = "none";
      } else {
        // если нет
        moreBtn.style.display = "inline-block";
        // подсчет оставшегося количества выводимых пунктов
        if (itemList.length - currentStep >= step) {
          stepIndicator.innerHTML = step;
        } else {
          stepIndicator.innerHTML = itemList.length - currentStep;
        }
        
      }
    };

    let removeOpen = function() {
      itemList.forEach(function(item) {
        item.classList.remove("is-open");
        item.classList.remove("is-new-open");
        if (animationClass) {
          item.classList.remove(animationClass);
        }
        if (useDisplay) {
          item.style.display = "none";
        }
      });
    };

    // прокрутка
    let scroll = function(elem) {
      // смещение от верха
      let ofset = option.ofset || 0;
      // длительность скрола
      let duration = option.duration || 50;
      // начальная прокрутка страницы
      let startPageYOffset = window.pageYOffset;

      // положение элемента на странице по Y
      function getCoords(elem) {
        let box = elem.getBoundingClientRect();
        return {
          top: box.top + pageYOffset,
          left: box.left + pageXOffset
        };
      }

      let boxCoords = getCoords(elem);
      let elemYOffset = boxCoords.top;

      // Действия:

      // получаем разницу
      let difference = elemYOffset - startPageYOffset - ofset;

      // делим ее на интервалы
      let perTick = difference / duration;

      // задаем переменную для сохранения текущего положения прокрутки
      let currentPageYOffset = startPageYOffset;

      //  запускаем функцию
      scrollToUniversal(duration);

      // функция прокрутки (реккурсивная)
      function scrollToUniversal(duration) {
        if (duration < 1) return;

        currentPageYOffset = currentPageYOffset + perTick;
        window.scrollTo(0, currentPageYOffset);
        // console.log(currentPageYOffset);

        setTimeout(function() {
          scrollToUniversal(duration - 1);
        }, 4);
      }
    };

    // основной код
    moreBtn.onclick = function(event) {
      addOpen();
    };

    addOpen();
  }
};
