/*

    :: Простой попап v. 1.1 ::

Принимает настройки:

openTag: ".js-open-spopup" - тег кнопки открытия
closeTag: "js-close-spopup" - тег кнопки закрытия
bodyClass: ".is-form-rewiev-open" - класс для body 

Действие: по клику на openTag добавляет на body класс bodyClass

Подключение: 
let popupSimple = require("./popupSimple");

Настройки:
popupSimple({
    openTag: ".js-open-spopup", 
    closeTag: ".js-close-spopup", 
    bodyClass: "is-form-rewiev-open"
});

*/

module.exports = function (option) {

    // проверка
    if (option.openTag && option.closeTag && option.bodyClass) {
        if (document.querySelector(option.openTag) && document.querySelector(option.closeTag)) {
            run(option);
        } else {
            if (document.querySelector(option.openTag) || document.querySelector(option.closeTag)) {               
                if (!document.querySelector(option.openTag)) {
                    console.log("не найден кнопка открытия");
                } 
                if (!document.querySelector(option.closeTag)) {
                    console.log("не найден крестик закрытия");
                }  
                if (!option.bodyClass) {
                    console.log("не указан класс для body");
                }               
            }

        }
    } 

    // запуск 
    function run() {
        let openList = document.querySelectorAll(option.openTag);
        openList = Array.from(openList);
        let closeList = document.querySelectorAll(option.closeTag);
        closeList = Array.from(closeList);


        openList.forEach(function(item){
            item.onclick = function (event) {
                event.preventDefault();
                document.body.classList.add(option.bodyClass);
            }
        });

        closeList.forEach(function(item){
            item.onclick = function (event) {
                event.preventDefault();
                document.body.classList.remove(option.bodyClass);
            }
        });

    }
}
