/*

    ::  lightning - верхнее сообщение-молния  ::

Принимает настройки:

rootTag - обертка
closeTag - кнопка закрытия
closeClass - класс закрытия

Действие: по клику на closeTag добавляет к rootTag класс closeClass

Подключение: 
let lightning = require("./lightning");

Настройки:
lightning({
    rootTag: ".js-lightning",
    closeTag: ".js-lightning-close",
    closeClass: "is-lightning-close"
});

*/

module.exports = function (option) {

    // проверка
    if (document.querySelector(option.rootTag)) {
        let rootEl = document.querySelector(option.rootTag);
        if (rootEl.querySelector(option.closeTag)) {
            run(option);
        } else {
            // console.error("не задана кнопка закрытия"); 
        }
    } else {
        // console.error("не заданы параметры"); 
    }

    // запуск 
    function run(option) {

        let rootEl = document.querySelector(option.rootTag);
        let closeBtn = rootEl.querySelector(option.closeTag);
        let closeClass = option.closeClass || "is-lightning-close";

        closeBtn.onclick = function (e) {
            rootEl.classList.add(closeClass);
        }  
    }

}
