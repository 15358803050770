/*

    :: Попап для отзывов v. 1.0 ::

Принимает настройки:

itemTag: ".js-review-item", - тег пункта отзыва
btnTag: ".js-open-review" - тег кнопки открытия отзыва (внутри пункта)
closeTag: "js-popup-close" - закрывает попап

Действие: по клику на btnTag добавляет на item класс "is-popup-open",
берет адрес картинки из ссылки

Подключение: 
let popupReview = require("./popupReview");

Настройки:
popupReview({
    itemTag: ".js-review-item",
    btnTag: ".js-open-review",
    closeTag: "js-popup-close"
});

*/

module.exports = function (option) {

    // проверка
    if (option.itemTag && option.btnTag  && option.closeTag) {
        if (document.querySelector(option.itemTag) && document.querySelector(option.btnTag) && document.querySelector(option.closeTag)) {
            run(option);
        } else {
            if (!document.querySelector(option.itemTag)) {
                console.error("не найден контейнер");
            }                 
            if (!document.querySelector(option.btnTag)) {
                console.log("не найден кнопка открытия отзыва");
            } 
            if (!document.querySelector(option.closeTag)) {
                console.log("не найден крестик закрытия");
            } 
        }
    } else {
        console.error("не заданы параметры попапа"); 
    }

    // запуск 
    function run() {

        var itemListNode = document.querySelectorAll(option.itemTag);
        var itemList = Array.prototype.slice.call(itemListNode, 0);

        // основной код
        run.setInit = function () {
            itemList.forEach (function (item, i, arr) {
                let btn = item.querySelector(option.btnTag);
                let close = item.querySelector(option.closeTag);

                btn.onclick = function () {
                    item.classList.add('is-popup-open');
                    document.body.classList.add('is-popup-rewiev-open');
                }

                close.onclick = function () {
                    item.classList.remove('is-popup-open');
                    document.body.classList.remove('is-popup-rewiev-open');
                }
            });
        }
        
        run.setInit();  
    }

}
