"use strict";
/**

@module liveFilter
@name Фильтр сотрировки таблиц
@kind module
@version 1.3

@description 
Сортирует строки таблицы в зависимости от переключения фильтров.
Открывает пункты из пошагово, с заданным интервалом. 

![](liveFilter.png)

##### Особенности структуры
Фильтры и фильтруемая таблица должны быть внутри `rootTag`.
Фильтры внутри формы с классом `controlFormTag`.
У формы должны быть атрибуты `method="POST" enctype="multipart/form-data"`
Поля фильтров `controlTag` (текстовые инпуты, чекбоксы, радиокнопки) 
расположены внутри формы, обязательно должны иметь имя и значение.

##### Особенности чекбоксов
Если чекбоксы объединены в одну группу (имеют одно имя) и хотя бы один включен, 
то фильтрация происходит по активным чекбоксам. 
Если ни один из них не влючен, показываются все значения.

##### Особенности работы
На время фильтрации добавляет к корневому элементу класс `is-filter-sorting`. 
В конце фильтрации добавляет к корневому элементу класс `filter-was-sorted`.
Если в результате фильтрации количество выводимых элементов стало равно нулю,
к корневому элементу `rootTag` добавляется класс `is-empty-open-item`
Используется оъект formData, который не понимают IE и Safari
Если ни один фильтр не включен, выводится все

##### Категории
Если вложить пункты внутри блоков категории и добавить этим блокам класс,
соответствующий опции categoryTag, то такие категории будут скрываться,
если в результате фильтрации в них не будет пунктов 

```
<div class="checkbox">
  <input type="checkbox" class="js-control" name="address" id="adress1" value="Большая Пушкарская" checked>
  <input type="checkbox" class="js-control" name="address" id="adress2" value="ул. Жуковского" checked>
</div>    
<div class="radio-list">
  <input type="radio" class="schedule-filter__radio js-control" name="time" id="tame1" value="Сегодня">
  <input type="radio" class="schedule-filter__radio js-control" name="time" id="tame2" value="Завтра">
  <input type="radio" class="schedule-filter__radio js-control" name="time" id="tame3" value="Вся неделя" checked>
</div>
<div class="input-text">
  <input type="text" class="djs-control" name="yo_class" id="yo_class" placeholder="Класс" value="Все классы" readonly  data-required="false">
</div>
```
В пунктах itemTag, в атрибуте data-filter должны находится JSON-объект, 
свойства которого должны соответствовать именам фильтров,
а значения - содержать массив строк, которое это свойство может принимать у данного пункта.
```
<div class="js-shedule-category">
  <div class="js-shedule-item" data-filter='{
      "address": ["ул. Жуковского"],
      "yo_class": ["Все классы", "Основной"],
      "time": ["Вся неделя", "Сегодня"],
  }'>Содержание пункта1</div>
  <div class="js-shedule-item" data-filter='{
      "address": ["ул. Жуковского"],
      "yo_class": ["Все классы", "Основной"],
      "time": ["Вся неделя", "Завтра"],
  }'>Содержание пункта2</div>
</div>
```
@property {string} rootTag - класс корня
@property {string} itemTag - класс пунктов списка
@property {string} [categoryTag] - класс категории 
@property {string} controlFormTag - тег формы <form action="" class="js-control-form"  method="POST" enctype="multipart/form-data">
@property {string} controlTag - тег каждого контрола (фильтра)
@property {number | string} [delay=0] - скорость появления пунктов (может сильно затормаживать сортировку)
@property {function} [animationCallback] - функция обработки пунктов (строк) таблицы. См. далее. 

@see liveFilter

@example <caption>Подключение</caption>
let liveFilter = require("./liveFilter");

@example <caption>Использование</caption>
// фильтры таблиц
liveFilter({
    rootTag: ".js-shedule",
    itemTag: ".js-shedule-item",
    categoryTag: ".js-shedule-category"
    controlFormTag: ".js-control-form",
    controlTag: ".js-control",
    delay: 0,
    animationCallback: function aimationCallback (itemNode, result) {
        itemNode.style.display = "none";
        if (result) {
            itemNode.style.display = "";               
        } 
    }
});

@example <caption>Функция по умолчанию</caption>  
let defaultAimationCallback = function (itemNode, result) {
    if (result) {
        itemNode.style.display = "";                    
    } else {
        itemNode.style.display = "none";
    }
}

 */




module.exports = function(option) { 

// добавить проверку опций
    

    // запуск 
    function init(option) {


        // НАСТРОЙКИ 

        // действие по умолчанию над пунктом после фильтрации
        let defaultAimationCallback = function (itemNode, result) {
            if (result) {
                itemNode.style.display = ""; 
                // itemNode.classList.add("is-visible");                  
            } else {
                itemNode.style.display = "none";
                // itemNode.classList.remove("is-visible");
            }
        }

        // создание списка форм
        let rootList = document.querySelectorAll(option.rootTag);
        rootList = Array.from(rootList);

        let animationCallback = option.animationCallback || defaultAimationCallback;
        let delay = option.delay || 0;
        let rootCount = 0;



        // ИНИЦИАЛИЗАЦИЯ
        // инициализация форм (перебор форм с фильтрами)
        rootList.forEach(function (rootEl) {            
            doRootInit(rootEl, option);
            rootCount = rootCount + 1;
        });

        // инициализация одной формы с фильтром
        function doRootInit(rootEl, option) {

            // подготовка списка дочерних элементов
            let form = rootEl.querySelector(option.controlFormTag);          
            let itemList = rootEl.querySelectorAll(option.itemTag);
            itemList = Array.from(itemList);
            let controlList = rootEl.querySelectorAll(option.controlTag);
            controlList = Array.from(controlList);
            let itemArray = [];
            let itemTotalCount;
            let itemActiveCount;

            // создание массива из фильтруемого списка
            itemList.forEach(function (item) {
                doItemsArray(itemArray, item);
            }); 

            // перебор и инициализация фильтров
            controlList.forEach(function (control) {
                control.onchange = function(e) {
                    console.time("all");
                    // добавляем класс анимации на root
                    rootEl.classList.remove("filter-was-sorted");
                    rootEl.classList.add("is-filter-sorting");

                    // получаем объект с настройками фильтров
                    let formDataObj = getFilterOption(form);

                    if(JSON.stringify(formDataObj) == "{}") {
                        // если не включен ни один фильтр
                        // показать все пункты
                        itemArray.forEach(function (item) {
                            animationCallback(item[0], true);
                        });
                        // показать все категории
                        filterCategory(rootEl);
                        // отключаем анимацию
                        rootEl.classList.remove("is-filter-sorting");
                        rootEl.classList.add("filter-was-sorted"); 
                        console.log("открыты все " + itemArray.length + " элементов");  
                    } else {
                        // если фильтры включены
                        itemArray.forEach(function (item) {
                            animationCallback(item[0], false);
                        });

                        // фильтрация данных 
                        // (передаем туда объект с пунктами списка и фильтрами)
                        doListFiltering(itemArray, formDataObj, rootEl);

                        // отправка настроек на сервер
                        // sendAjax(form, formDataObj);                         
                    }

                }
            });
        }



        // ФИЛЬТРАЦИЯ

        // создание массива из фильтруемого списка
        function doItemsArray(itemArray, item) {
            if (item.dataset.filter) {
                let itemData = item.dataset.filter;
                itemData = JSON.parse(itemData); 
                let insetData = [item, itemData];
                itemArray.push(insetData);
            } else {
                console.error(item, " не найденны данные для фильтрации");
                // item.style.backgroundColor = "rgba(255,0,0,0.5)";
            } 
        }

        // сбор состояний фильтров
        function getFilterOption(form) {
            // собираем все поля (name, value) в объект formData
            let formData = new FormData(form);
            // превращаем formData в объект formDataObj, 
            // содержащий свойства в виде массивов
            let formDataObj = {};

            // перебираем с помощью итератора formData.entries()
            // если браузер его не поддерживает (ie, safari)
            // можно подключить полифил https://www.npmjs.com/package/formdata-polyfill

            for(var pair of formData.entries()) {
               
               let controlName = pair[0];
               let controlValue = pair[1];
               if (!formDataObj[controlName]) {                     
                    formDataObj[controlName] = [];
                }
                formDataObj[controlName].push(controlValue); 
                // console.log(controlName, controlValue); 
            }

            // console.dir(formDataObj); 

            return formDataObj;
        }

        // фильтрация данных
        function doListFiltering(itemArray, formDataObj, rootEl) {

            // создание счетчика задержки
            let delayLocal = delay;
            // создание счетчика открытых пунктов
            let countOpenItem = 0;

            // перебор массива с фильтруемыми пунктами
            itemArray.forEach(function (listitem, i) {  

                let itemNode = listitem[0]; // элемент списка (пункт) в разметке
                let itemData = listitem[1]; // массив настроек фильтрации этого элемента

                var setFinalResultBind = setFinalResult.bind(listitem);
                listitem.result = false;

                function generalCountCallback() {
                    // если пункт будет показан, увеличиваем счетчик
                    if (listitem.result) {
                        countOpenItem = countOpenItem + 1;                            
                    }

                    // если массив перебран, выводим общий результат
                    if (i == itemArray.length - 1) {
                        console.log("открыто " + countOpenItem + " из " + itemArray.length); 
                        setCountOpenItem(countOpenItem, rootEl);
                    }
                }

                // создание объекта с настройками
                let compareData = {
                    listitem: listitem,
                    formDataObj: formDataObj, // общий массив настроек фильтров
                    itemData : itemData, // массив настроек фильтрации этого элемента
                    setFinalResult: setFinalResultBind, // функция подсчета итогов
                    itemNode: itemNode, // элемент списка (пункт) в разметке
                    animationCallback: animationCallback // функция анимации
                }; 

                // создание промиса и запуск сравнения
                function getPromise() {                    
                    
                    //  Создаётся объект promise
                    let promise = new Promise((resolve, reject) => {
                        compareArr(compareData, resolve);
                    });

                    promise.then(
                        result => {
                            generalCountCallback()
                        }
                    );
                }

                // задержка срабатывания (для анимации)
                if (delay) {
                  setTimeout(getPromise, delayLocal);
                  delayLocal = delayLocal + delay; 
                } else {
                  getPromise();
                }
    
                    
            }); 

            
            // функция сравнения массивов
            function compareArr(compareData, resolve) {
                let listitem = compareData.listitem;
                let formDataObj = compareData.formDataObj;
                let itemData = compareData.itemData;
                let setFinalResult = compareData.setFinalResult;
                let itemNode = compareData.itemNode;
                let animationCallback = compareData.animationCallback;
                // хранилище результатов
                let resultArr = [];               

                // подсчет количества свойств в объекте
                let formDataObjLength = Object.keys(formDataObj).length;
                let formDataObjIndex = formDataObjLength;

                

                // функция добавляет результат свойства в массив результатов
                function setLocalResult(formInnerArrIndex, formInnerLength, localResult) {
                    // проверяем, это последний локальный цикл
                    // и тогда отправляем локальный результат свойства в массив результатов
                    if(formInnerArrIndex + 1 === formInnerLength) {
                        resultArr.push(localResult);
                        // проверяем, что formDataObjIndex = 0 (последнее свойство)
                        // и тогда считаем итоговый результат
                        if(!formDataObjIndex) {
                            resolve("result");                            
                            return setFinalResult(resultArr, itemNode, animationCallback);                                                                                
                        }
                    }                                
                }
                
                for(let key in formDataObj) {
                    // подсчет количества свойств в объекте
                    formDataObjIndex = formDataObjIndex - 1;

                    // перебираем первый уровень объекта formDataObj (объект фильтров)
                    // убеждаемся, что такое свойство есть у пункта
                    if (!itemData[key]) {
                        console.error("свойство " + key + " не найдено в строке"); 
                        break;
                    } else {
                        // если свойство есть, проверяем его на тип массив 
                        if (Array.isArray(itemData[key])) {
                            // console.log("  Свойство: " + key);                             

                            // выбираем текущие свойства
                            let itemInnerArr = itemData[key]; // масив свойства пункта
                            let formInnerArr = formDataObj[key]; // масив свойства фильтров
                            let localResult = false; 
                            let formInnerLength = formInnerArr.length; // подсчет значений в массиве свойства

                            // перебираем значения свойства фильтров
                            // console.log("forEach6"); 
                            formInnerArr.forEach(function(itemInner, formInnerArrIndex) {

                                // для каждого ищем соответствие в массиве свойства пункта
                                if( itemInnerArr.includes(itemInner) )  {
                                    // если совпадает хотя бы одно значение 
                                    // свойство принимает значение true
                                    // console.log("значение " + itemInner + " найдено"); 
                                    localResult = true;
                                    setLocalResult(formInnerArrIndex, formInnerLength, localResult);

                                } else {
                                    // console.log("значение " + itemInner + " не найдено");
                                    setLocalResult(formInnerArrIndex, formInnerLength, localResult);
                                }
                            });


                        } else {
                            console.error("свойство " + key + " не массив"); 
                            break;                            
                        }
                    }
                }                    
            }

            // функция определения итогового результата
            function setFinalResult(resultArr, itemNode, animationCallback) {

                // проверка на ненайденные свойства
                // если не найдет в массиве результатов false, вернет 0
                function findFalseResult() {                
                    return ~find(resultArr, false); 
                }

                // возвращаем итоговый результат сравнения фильтров и пункта фильтруемых данных 
                if(!findFalseResult())  {
                    // пункт будет показан
                    animationCallback(itemNode, true);
                    this.result = true;
                    return true;                    
                } else {
                    // пункт будет скрыт
                    animationCallback(itemNode, false);
                    this.result = false;
                    return false;
                }


                
            }

            // изменения стилей root после окончания фильтрации активация сообщения об отсутствии найденных пунктов
            function setCountOpenItem(countOpenItem, rootEl) {

                if (!countOpenItem) {
                    rootEl.classList.add("is-empty-open-item");
                } else {
                    rootEl.classList.remove("is-empty-open-item");
                }

                rootEl.classList.remove("is-filter-sorting");
                rootEl.classList.add("filter-was-sorted");                

                filterCategory(rootEl);
            }


        }



        // СЛУЖЕБНЫЕ ФУНКЦИИ

        // функция поиска в массиве
         function find(array, value) {
            if ([].indexOf) {
                return array.indexOf(value);
            } else {
                for (var i = 0; i < array.length; i++) {                    
                    if (array[i] === value) return i;
                }
                return -1;
            }
        }
                
        // отправка настроек фильтра на сервер
        // function sendAjax(form, formData) {
        //     // body...
        // }
        // 
        
        // фильтрация категории
        function filterCategory(rootEl) {

            console.timeEnd("all");

            if(option.categoryTag) {

                let categoryTag = option.categoryTag;
                let itemTag = option.itemTag;

                let categoryList = rootEl.querySelectorAll(categoryTag);
                categoryList = Array.from(categoryList);

                categoryList.forEach(function(category){
                    let itemList = category.querySelectorAll(itemTag);
                    itemList = Array.from(itemList);

                    if (itemList.length > 0) {

                        let openCategory = false;

                        itemList.forEach(function(item){
                            if(item.style.display != "none") {
                                openCategory = true;
                            }
                        });

                        if (openCategory) {
                            category.style.display = "";
                        } else {
                            category.style.display = "none";
                        }
                        
                    }
                    
                });

            }

        }

           
    }

    init(option);

   
};