"use strict";
/**

@module expandList
@name Mобильное меню
@kind module
@version 1.2.4

@description 
Открывает/закрывает мобильное меню. 

Т.к. меню должно работать только на мобильных/планшетах, открытие реализовано через CSS. 
При нажатии на кнопку убирает/добавляет класс `is-menu-open` у `body`

Если задана только одна кнопка (`btnOpenTag`), она является переключателем.
Если заданы 2 кнопки, то `btnOpenTag` открывает, а `btnCloseTag` закрывает меню.
Наличие `linkCloseTag` позволяет закрывать меню из других мест, например, при нажатии на пункт в меню. 

![](mobileMenu.png) 

@property {string} btnOpenTag - тег кнопки открытия
@property {string} [[btnCloseTag]] - тег кнопки закрытия
@property {string} [[linkCloseTag]] - тег ссылки закрытия
@property {string} [[bodyOpenMenuClass]] - класс для body при открытии меню

@example <caption>Подключение</caption>
const mobileMenu = require("./mobileMenu");

@example <caption>Использование</caption>
// мобильное меню
mobileMenu ({
	btnOpenTag: ".js-btn-mobile-menu-open",
	btnCloseTag: ".js-btn-mobile-menu-close",
	linkCloseTag: ".js-mobile-menu-link",
	bodyOpenMenuClass: "is-menu-open"
});

*/

module.exports = function (option) {

	// console.log("подключено мобильное меню"); 

	// открытие и закрытие меню
	if (document.querySelector(option.btnOpenTag) ) {

		// переменные
		let btnOpenEl = document.querySelector(option.btnOpenTag);
		let btnCloseEl;
		let linkCloseListNode;
		let bodyOpenMenuClass = option.bodyOpenMenuClass || "is-menu-open";

		if (document.querySelector(option.btnCloseTag) ) {
			btnCloseEl = document.querySelector(option.btnCloseTag);
		}

		// функции
		let openFn = () => {
			document.body.classList.add(bodyOpenMenuClass);
			// console.log("mobile-menu open"); 
		}

		let closeFn = () => {
			document.body.classList.remove(bodyOpenMenuClass);
			// console.log("mobile-menu close"); 
		}

		let toggleFn = () => {
			document.body.classList.toggle(bodyOpenMenuClass);
			// console.log("mobile-menu toggle"); 
		}

		// варианты
		if (btnCloseEl) {
			btnOpenEl.addEventListener("click", openFn);			
			btnCloseEl.addEventListener("click", closeFn);
		} else {
			btnOpenEl.addEventListener("click", toggleFn);
		}

		// закрытие по ссылкам
		// console.log(option.linkCloseTag); 
		if (document.querySelector(option.linkCloseTag) ) {
			linkCloseListNode  = document.querySelectorAll(option.linkCloseTag);
			var linkCloseList = Array.prototype.slice.call(linkCloseListNode, 0);

			linkCloseList.forEach (function (linkCloseEl) {
				linkCloseEl.addEventListener("click", closeFn);
			});
		}

	}

};