class Popup {

    /**
     * adds popup to body
     * @param node html node or string
     */
    constructor(node) {
        let popUpHtml =
            `<div class="popup__overlay">
                <div class="popup__wrap">
                    <div class="popup__box">
                        <div class="popup__inner">
                          <div class="popup__close js-popup-close"></div>
                          <div class="popup__content">
                                ${node.outerHTML?node.outerHTML:node}
                          </div>
                        </div>
                    </div>
                </div>
             </div>`;
        let _this = this;

        this.widget = document.createElement('div');
        this.widget.classList.add('popup__widget');
        this.widget.classList.add('hidden');
        document.body.appendChild(this.widget);
        this.widget.insertAdjacentHTML('afterbegin',popUpHtml);

        this.widget.querySelector(".popup__close").addEventListener('click', function () {
            _this.close();
        });

        this.widget.querySelector(".popup__overlay").addEventListener("click", function (event) {
            if (this == event.target) {
                _this.close();
            }
        })
    }

    show() {
        document.body.style.overflow = "hidden";
        this.widget.classList.remove('hidden');
    }

    close() {
        document.body.style.overflow = "auto";
        this.widget.classList.add('hidden');
    }
}
module.exports = Popup;