/**

@module miniGallery
@name Галерея miniGallery

@description 
Меняет картинку в большом блоке при клике на превью. 

Принимает настройки в виде объекта `option` с параметрами.
Требует специальной HTML разметки. 
`rootTag` - корневой тег галереи.
`bigPhotoTag` - блок для вывода большой картинки в бекграунд.
`itemTag` - блоки с маленькими превью-картинками. 
У них есть дата атрибут `data-bigimage` в виде ссылки на большое изображение.
По клику на превью скрипт меняется фон у большой картинки на значение из `data-bigimage`.

@kind module
@version 1.0.1

@property {string} rootTag - тег корневого элемента
@property {string} bigPhotoTag - тег блока для вывода картинки
@property {string} itemTag - тег блоков с превью
@property {string} data - название дата-атрибута со ссылкой на большое изображение

@example <caption>Подключение</caption>
let miniGallery = require("./miniGallery");

@example <caption>Использование</caption>
// miniGallery
miniGallery ({
    rootTag: ".js-mini-gallery",
    bigPhotoTag: ".js-big-photo",
    itemTag: ".js-thumbs",
    data: "bigimage"  
});

@example <caption>HTML</caption>
<div class="gallery js-mini-gallery">
    <div class="gallery-big-box">
        <div class="gallery-big-photo js-big-photo" 
            style="background-image: url(/img/big-start.jpg);">
        </div>
    </div>
    <div class="gallery-thumbs-row">
        <div class="gallery-thumbs js-thumbs" data-bigimage="/img/big1.jpg" 
            style="background-image: url('/img/t1.jpg')">
        </div>
        <div class="gallery-thumbs js-thumbs" data-bigimage="/img/big2.jpg" 
            style="background-image: url('/img/t2.jpg')">
        </div>
        <div class="gallery-thumbs js-thumbs" data-bigimage="/img/big3.jpg" 
            style="background-image: url('/img/t3.jpg')">
        </div>
    </div>
</div>

*/


module.exports = function (option) {

    // проверка
    if (option.rootTag && option.bigPhotoTag && option.itemTag && option.data) {
        if (document.querySelector(option.rootTag) && document.querySelector(option.bigPhotoTag) && document.querySelector(option.itemTag)) {
            run(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден корневой элемент " + option.rootTag);
            } else {
                if (!document.querySelector(option.bigPhotoTag)) {
                    console.error("не найден блок для вывода картинки " + option.nameTag);
                }     

                if (!document.querySelector(option.itemTag)) {
                    console.error("не найдены превью " + option.itemTag);
                }                   
            }
        }
    } else {
        console.error("минигалерее не заданы необходимые параметры");  
    }

    // запуск слайдера
    function run(option) {
        // console.log("Минигалерея " + option.rootTag); 
        let rootList = document.querySelectorAll(option.rootTag);
        rootList = Array.from(rootList);

        let init = function(rootEl) {
            let bigPhoto = rootEl.querySelector(option.bigPhotoTag);
            let itemList = rootEl.querySelectorAll(option.itemTag);
            itemList = Array.from(itemList);

            itemList.forEach(function (item, i, arr) {
                item.onclick = function(e) {
                    console.log(bigPhoto); 
                    let imgUrl = item.getAttribute("data-" + option.data);
                    bigPhoto.style.backgroundImage =  "url("+imgUrl+")";

                }
            });
        }

        rootList.forEach(function (rootEl, i, arr) {
            init(rootEl);
        });
           
    }

};


