"use strict";
/**

@module expandListStep
@name Развернуть список пошагово
@kind module
@version 1.0.3

@description 
Сворачивает длинный список из однотипных пунктов до указанного значения. 
Добавляет кнопку разворота c указанием количества скрытых пунктов.

При инициализации добавляет скрывающимся пунктам `display: none`, 
При клике на кнопке разворота добавляет скрывающимся пунктам `display: block`,
открывая из пошагово, с заданным интервалом. 

![](expandList.png)

#####Отличия от expandList
В отличие от предыдущий версии, появление пунктов реализовано через свойство `display`, 
а не через добавление класса.
Количество скрытых пунктов всегда выводится после текста открытия списка,
свойство `numberHiddenTag` было убрано.
Пошаговый модуль стал менее требователен к разметке, 
он сам добавляет блок с кнопками открытия/закрытия после списка пунктов.

```
<span class="expand-list-step__pseudolink">
    <span class="expand-list-step__text-for-open">${textForOpen} 
        <span class="expand-list__number-hidden">${numberHiddenText}</span>
    </span>
    <span class="expand-list-step__text-for-close">${textForClose}</span>
</span>
```

@property {string} rootTag - класс корня списка (не обязательно ul)
@property {string} [itemTag=li] - класс пунктов списка
@property {number | string} numberDisplayItem - количество видимых пунктов
@property {string} readMoreTag - тег элемента с текстом для кнопки разворота

@property {number | string} [delay=50] - скорость появления пунктов
@property {string} [textForOpen=показать еще] - текст на ссылке открытия
@property {string} [textForClose=скрыть] - текст на ссылке закрытия
@property {string} [animationClass=is-appear] - класс анимации появления/скрытия пунктов
@property {boolean} [noClose=false] - не закрывать после открытия (ссылка закрытия исчезает). Введена вместо `expandAll`.

@see expandList

@example <caption>Подключение</caption>
let expandList = require("./expandListStep");

@example <caption>Использование</caption>
// раскрывающийся список
expandListStep({
    rootTag: ".js-expand-list-step",
    itemTag: ".js-expand-item",
    numberDisplayItem: "4",
    readMoreTag: ".js-read-more",
    delay: 30,
    textForOpen: "показать еще",
    textForClose: "скрыть",
    animationClass: "is-appear",
    noClose: false
});

@example <caption>HTML</caption>  
<div class="js-expand-root">
    <ul>
        <li class="js-expand-item">пункт 1</li>
        <li class="js-expand-item">пункт 2</li>
        <li class="js-expand-item">пункт 3</li>
    </ul>
    <div class="js-read-more"></div>
</div>

*/

module.exports = function(option) {
    // проверка
    if (
        option.rootTag &&
        option.itemTag &&
        option.readMoreTag &&
        option.numberDisplayItem
    ) {
        if (
            document.querySelector(option.rootTag) &&
            document.querySelector(option.itemTag) &&
            document.querySelector(option.readMoreTag)
        ) {
            run(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден аккордион");
            } else {
                if (!document.querySelector(option.itemTag)) {
                    console.error("не найден контейнер");
                }
                if (!document.querySelector(option.readMoreTag)) {
                    console.error("не найден элемент, раскрывающий список");
                }
                if (!option.numberDisplayItem) {
                    console.error("не задано количество видимых элементов");
                }
            }
        }
    } else {
        console.error("не заданы параметры списка");
    }

    // запуск
    function run() {
        // console.log("Раскрывающийся список " + option.rootTag);
        let rootElList = document.querySelectorAll(option.rootTag);
        rootElList = Array.from(rootElList);
        let numberDisplayItem = option.numberDisplayItem;
        let delay = option.delay || 50;
        let showNumberHidden = option.showNumberHidden || true;
        let textForOpen = option.textForOpen || "показать еще ";
        let textForClose = option.textForClose || "свернуть ";
        let animationClass = option.animationClass || "";

        // основной код
        let setInit = function() {
            // перебор списков
            rootElList.forEach(function(rootEl) {
                // проверка на наличие пунктов (могут быть списки без пунктов)
                if (rootEl.querySelector(option.itemTag)) {
                    let itemList = rootEl.querySelectorAll(option.itemTag);
                    itemList = Array.from(itemList);
                    let readMoreEl = rootEl.querySelector(option.readMoreTag);
                    let isOpen = false;
                    let numberAllItems = itemList.length;
                    let openEl, closeEl;

                    // закрытие
                    let close = function() {
                        // console.log("close");
                        // выбираем пункты для закрытия, создаем новый массив
                        var closeList = itemList.filter(function(item, i) {
                            return i >= numberDisplayItem;
                        });
                        // разворачиваем массив
                        closeList.reverse();
                        let delayLocal = delay;
                        closeList.forEach(function(item) {
                            item.classList.add(animationClass);
                            setTimeout(function() {
                                item.style.display = "none";
                            }, delayLocal);
                            delayLocal = delayLocal + delay;
                        });
                        openEl.style.display = "inline";
                        closeEl.style.display = "none";
                    };

                    let open = function() {
                        // console.log("open");
                        let delayLocal = delay;
                        itemList.forEach(function(item, i) {
                            if (i >= numberDisplayItem) {
                                setTimeout(function() {
                                    item.style.display = "block";
                                    item.classList.add(animationClass);
                                }, delayLocal);
                                delayLocal = delayLocal + delay;
                            }
                        });
                        openEl.style.display = "none";
                        closeEl.style.display = "inline";
                    };

                    // инициализация
                    (function() {
                        // проверка количества скрытых элементов
                        let numberHiddenItems =
                            numberAllItems - numberDisplayItem;
                        let numberHiddenText = "";
                        if (showNumberHidden) {
                            numberHiddenText = numberHiddenItems;
                        }

                        // скрытие кнопки при инициализации списка
                        readMoreEl.style.display = "none";
                        readMoreEl.innerHTML = `<span class="expand-list-step__pseudolink">
                                                    <span class="expand-list-step__text-for-open">${textForOpen} 
                                                        <span class="expand-list__number-hidden">${numberHiddenText}</span>
                                                    </span>
                                                    <span class="expand-list-step__text-for-close">${textForClose}</span>
                                                </span>`;

                        // показать количество скрытых пунктов?
                        if (numberHiddenItems > 1) {
                            // показать кнопку раскрытия
                            readMoreEl.style.display = "block";

                            // и добавить ей события
                            readMoreEl.onclick = function() {
                                if (isOpen) {
                                    if (!option.noClose) {
                                        close();
                                        isOpen = false;
                                    }
                                } else {
                                    open();
                                    isOpen = true;

                                    if (option.noClose) {
                                        readMoreEl.style.display = "none";
                                    }
                                }
                            };
                        }

                        setTimeout(function() {
                            openEl = readMoreEl.querySelector(
                                ".expand-list-step__text-for-open"
                            );
                            closeEl = readMoreEl.querySelector(
                                ".expand-list-step__text-for-close"
                            );
                            openEl.style.cursor = "pointer";
                            closeEl.style.cursor = "pointer";

                            close();
                            isOpen = false;
                        }, 50);
                    })();
                }
            });
        };

        setInit();
    }
};
