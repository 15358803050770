module.exports = {
    callback: function (currentCity) {
        return `<form data-metrikagoal="form-send" action="/sendmail/entry.php" method="post" class="js-form callback" id="form-order">
            <div class="callbackform-popup__inner callbackform-popup__inner--popup">                
                <div class="callbackform-popup__content js-content">
                    <div class="callbackform-popup__title-box">
                        <h2 class="callbackform-popup__title">Заявка на обратный звонок</h2>
                    </div>
                    <input type="hidden" name="city" class="js-form-city" val="" data-required="false" />
                    <input type="hidden" name="url" class="js-form-url" val="" data-required="false" />
                    <div class="callbackform-popup__promo">Оставьте номер телефона, менеджер перезвонит вам в ближайшее время</div>
                    <div class="callbackform-popup__promo-form-row">
                        <div class="callbackform-popup__promo-tel-box">
                            <input name="tel" type="text" class="callbackform-popup__promo-tel callbackform-popup__promo-tel--index  js-tel" placeholder="+7 (___) ___-__-__" data-field-type="maskphone" >
                        </div>
                        <div class="callbackform-popup__promo-btn-box">
                            <input type="submit" class="callbackform-popup__promo-btn" value="Нужен мастер" >
                        </div>
                    </div>
                    <div class="autoforms_errors">скрыто</div>
                </div>
                <div class="js-form-answers answer">
                    <div class="js-preloader answer__preloader answer__preloader--callbackform-popup"></div>
                    <div class="answer__item answer__item--callbackform-popup js-fail">
                        <div class="answer__answer-box">
                            <div class="answer__inner">
                                <div class="answer__title">
                                    Не удалось отправить заявку
                                </div>
                                <div class="answer__content">
                                    <p>Скоро мы решим проблему,<br>
                                        а пока вы можете позвонить менеджеру<br>
                                        по телефону, указанному на сайте</p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="answer__item answer__item--callbackform-popup js-success">
                            <div class="answer__box">
                              <div class="answer__inner">
                                    <div class="answer__title">
                                        Заявка отправлена
                                    </div>
                                    <div class="answer__content">
                                        <p>Ждите звонка менеджера компании<br>в&nbsp;ближайшее время</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                  
                </div>
            </form>`
    },
};
