/*

::  JS-star - общие ссылки  ::
Только один чекбокс может быть выбран

Принимает настройки:
rootTag: ".js-only-one-checkbox-selected", - тег обертки группы чекбоксов

Действие: по клику на один невыбранный чекбокс снимает выделение у других

// только один чекбокс может быть выбран
let onlyOneCheckboxSelected = require("./onlyOneCheckboxSelected");

// только один чекбокс может быть выбран
onlyOneCheckboxSelected(rootTag);

*/

module.exports = function (rootTag) {

  // проверка
  if (document.querySelector(rootTag) ) {
    run(rootTag);
  } else {
    // console.error("не заданы параметры"); 
  }

  // запуск 
  function run(rootTag) {

    var rootList = document.querySelectorAll(rootTag);

    // основной код
    rootList.forEach((rootEl) => {
      let checkList = rootEl.querySelectorAll("input[type=checkbox]");

      checkList.forEach((input) => {        
        input.addEventListener("change", (e) => {
          checkList.forEach((input) => {            
            if(input !== e.target) {
              input.checked = false;
            }
          });
        });
      });

    });
  }

}
