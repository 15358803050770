/*

    :: Простой аккордеон v. 1.62 ::

Принимает настройки:

rootTag - класс аккордеона
itemTag - класс пунктов
toggleTag - элемент открытия/закрытия аккордеона (если не задан, его роль выполняет itemTag)
scroll - наличие прокрутки к выбранному пункту(необязательный параметр)
ofset - смещение прокрутки (необязательный параметр, по умолчанию 0)
duration - длительность прокрутки (необязательный параметр, по умолчанию 50)
allOpen - сделать все вкладки открытыми (необязательный параметр)


Действие: по клику на item добавляет класс "is-open"

Подключение: 
let simpleAccord = require("./simpleAccord");

Настройки:
simpleAccord({
    rootTag: ".js-accordeon",
    itemTag: ".js-table-row",
    toggleTag: ".js-toggle"
    scroll: true,
    ofset: 0,
    duration: 50,
    allOpen: false
});
*/

module.exports = function (option) {
    // проверка
    if (option.rootTag && option.itemTag) {
        if (document.querySelector(option.rootTag) && document.querySelector(option.itemTag) ) {
            runAccordion(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден аккордеон " + option.rootTag);
            } else {
                if (!document.querySelector(option.itemTag)) {
                    console.error("не найден пункт " + option.itemTag);
                }                
            } 
        }
    } else {
        console.error("не заданы необходимые параметры"); 
    }

    // запуск аккордиона
    function runAccordion () {
        // console.log("Подключен аккордеон" + option.rootTag); 
        var current;
        var rootEl = document.querySelector(option.rootTag);
        var itemListNode = rootEl.querySelectorAll(option.itemTag);
        var itemList = Array.prototype.slice.call(itemListNode, 0);
            
        // основной код

        let setInit = function () {
            itemList.forEach (function (item, i, arr) {

                if (option.allOpen) {
                    allOpen();
                } else {
                   removeOpen(); 
                }

                let toggleBtn;
                if (item.querySelector(option.toggleTag)) {
                    toggleBtn = item.querySelector(option.toggleTag);
                } else {
                    toggleBtn = item;
                }

                toggleBtn.onclick = function (event) {

                    if (item.classList.contains('is-open') && option.toggleTag ) {
                        item.classList.remove('is-open');
                    } else {
                        removeOpen();
                        item.classList.add('is-open');
                        if (option.scroll) {
                           scroll(item); 
                        }
                        
                    }

                }
            });
        };
        
        let removeOpen = function () {
            itemList.forEach (function (item, i, arr) {
                item.classList.remove('is-open');
            });
        };

        let allOpen = function () {
            itemList.forEach (function (item, i, arr) {
                item.classList.add('is-open');
            });
        };

        // прокрутка
        let scroll = function (elem) {
            // смещение от верха
            let ofset = option.ofset || 0;
            // длительность скрола
            let duration = option.duration || 50;

            // начальная прокрутка страницы
            let startPageYOffset = window.pageYOffset;

            // положение элемента на странице по Y
            function getCoords(elem) { 
                let box = elem.getBoundingClientRect();
                return {
                    top: box.top + pageYOffset,
                    left: box.left + pageXOffset
                };
            }

            let boxCoords = getCoords(elem);
            let elemYOffset = boxCoords.top;

            // Действия:

            // получаем разницу
            let difference = elemYOffset - startPageYOffset - ofset;

            // console.log("elemYOffset: " + elemYOffset);
            // console.log("startPageYOffset: " + startPageYOffset);
            // console.log("ofset: " + ofset);
            // console.log("difference: " + difference); 
            // делим ее на интервалы
            let perTick = difference / duration;
            // задаем переменную для сохранения текущего положения прокрутки
            let currentPageYOffset = startPageYOffset;

            //  запускаем функцию
            scrollToUniversal(duration);

            // функция прокрутки (реккурсивная)
            function scrollToUniversal(duration) {

                if (duration < 1) return;
                
                currentPageYOffset = currentPageYOffset + perTick;
                window.scrollTo(0,currentPageYOffset);
                // console.log(currentPageYOffset); 

                setTimeout(function() {
                    scrollToUniversal(duration - 1);
                }, (4) );
            }
          
        };

        setInit();  

    }

};


