const onBlock = require("./onblock");
module.exports = function (readyCallback) {
    let myMap = null;

    ymaps.ready(init);

    function init() {
        onBlock("#ya_map", function () {
            myMap = new ymaps.Map("ya_map", {
                center: [59.919420, 30.464895],
                zoom: 15,
                controls: []
            });
        });

        readyCallback(ymaps, myMap);
    }
};