"use strict";
/**

@module autoScrollToTag
@name Плавный скрол к
@kind module
@version 1.0.6

@description 
Ищет все теги, у которых класс содержит TAG.
Блокирует стандартное действие ссылки.
Берет место назначения из атрибута `href` или `data-href` 
По клику делает плавный скрол.

Настройки:
@property {string} itemTag - класс ссылки
@property {number} [duration=100] - количество шагов
@property {number | string} [speed=1000] - скорость скрола, по умолчанию 1000
@property {number | string} [ofset=0] - отступ сверху
@property {string} [parentTag] - родительский элемент, высота которого влияет на отступ при прокрутке
@property {number} [noParentOfsetLessWhen] - значение ширины страницы, 
    менее которого высота отступа родительского элемента не будет применяться. 
    Это нужно, когда в декстопном варианте меню плавающее, а при более узком экране - статичное. 


@example <caption>Подключение</caption>
let autoScrollToTag = require("./autoScrollToTag");

@example <caption>Использование</caption>
// плавный скрол к якорям
autoScrollToTag({
    itemTag: ".js-scroll",
    duration: "50", 
    speed: "1000",
    ofset: 30,
    parentTag: ".js-scroll-parent",
    noParentOfsetLessWhen: 1150
});


*/

module.exports = function (option) {

    let duration = option.durat || 100;
    let itemTag = option.itemTag || 'a[href^="#"]';
    let speed = option.speed || 1000;
    let ofset = option.ofset || 0;
    let parentTag = option.parentTag;
    let noParentOfsetLessWhen = option.noParentOfsetLessWhen || 0;
    let linkNodeList = document.querySelectorAll(itemTag); 
    var linkList = Array.prototype.slice.call(linkNodeList, 0);   

    linkList.forEach (function (linkEl) {

        let link = linkEl.getAttribute("href") || linkEl.getAttribute("data-href");

        if (linkEl.getAttribute("href") ) {
            link = linkEl.getAttribute("href")
        } else {
            if (linkEl.hasAttribute("data-href") ) {
                link = linkEl.getAttribute("data-href");
            }            
        }

        if (link == null || "") {
            console.error(linkEl.outerHTML + " не ссылка"); 
            return;
        }

        if (!document.querySelector(link)) {
            console.error("битая ссылка на " + link); 
            return;
        }

        if  (link.length > 2) {

            let element = document.querySelector(link);

            // событие
            linkEl.onclick = function (event) {
                event = event || window.event;
                // preventDefault +ie8
                event.preventDefault ? event.preventDefault() : (event.returnValue=false);

                // начальная прокрутка страницы
                let startPageYOffset = window.pageYOffset;

                // положение элемента на странице по Y
                function getCoords(elem) { // кроме IE8-
                    let box = elem.getBoundingClientRect();
                    return {
                        top: box.top + pageYOffset,
                        left: box.left + pageXOffset
                    };
                }

                let boxCoords = getCoords(element);
                let elemYOffset = boxCoords.top;

                let ofsetParent = 0;
                if (document.querySelector(parentTag) && (window.innerWidth > noParentOfsetLessWhen) ) {
                    ofsetParent = document.querySelector(parentTag).offsetHeight;   
                }
                
                // Действия:

                // получаем разницу
                let difference = elemYOffset - startPageYOffset - ofset - ofsetParent;

                // console.log("elemYOffset: " + elemYOffset);
                // console.log("startPageYOffset: " + startPageYOffset);
                // console.log("ofsetParent: " + ofsetParent);
                // console.log("ofset: " + ofset);
                // console.log("difference: " + difference); 
                // делим ее на интервалы
                let perTick = difference / duration;
                // задаем переменную для сохранения текущего положения прокрутки
                let currentPageYOffset = startPageYOffset;
                //  запускаем функцию
                scrollToUniversal(duration);

                // функция прокрутки (реккурсивная)
                function scrollToUniversal(duration) {

                    if (duration < 1) return;
                    
                    currentPageYOffset = currentPageYOffset + perTick;
                    window.scrollTo(0,currentPageYOffset);
                    // console.log(currentPageYOffset); 

                    setTimeout(function() {
                        scrollToUniversal(duration - 1);
                    }, (speed/difference) );
                }

            }
        }

    });

}


