"use strict";

// ПОДКЛЮЧЕНИЕ

require('babel-polyfill')
require('./formdata-polyfill');

const $ = require("./jquery");
const jQuery = $;
window.jQuery = $;



// автоформы
const autoforms = require("./autoforms");

// Выпадающий список
let dropList = require("./dropList");

// maskedInput
const maskedInput = require("./maskedinput");

// отправка форм по аякс
const forms = require("./forms");

// шаблоны форм
const formsTemplates = require("./templates/forms");

// попапы
const Popup = require("./popup");
const popupReview = require("./popupReview");
let popupSimple = require("./popupSimple");

// поеказать еще группу
const readMoreGroup = require("./readMoreGroup.js");

// аккордеон
let simpleAccord = require("./simpleAccord");

// аккордеон и табы
let accordTab = require("./accordTab");

// цены
const accounting = require("./accounting");

// мобильное меню
const mobileMenu = require("./mobileMenu");

// геолокация + отдельно должен быть скрипт yandex.map-api
const Maps = require("./yamaps");
const Geolocation = require("./geolocation");
let _ymaps, _yMap;
var cities = $("body").data("cities");
const onBlock = require("./onblock");

// метрика
// let metrika = require("./metrika")(45150960);

// плавный скрол к якорям
let autoScrollToTag = require("./autoScrollToTag");

// слайдер
const slider = require("./simpleSlider");
const miniGallery = require("./miniGallery");
let expandListStep = require("./expandListStep");

// галерея
let initPhotoSwipe = require("./photoswipe/initPhotoSwipe");

// фильтр
let liveFilter = require("./liveFilter");

// молния
let lightning = require("./lightning");

// только один чекбокс может быть выбран
let onlyOneCheckboxSelected = require("./onlyOneCheckboxSelected");

// НАСТРОЙКИ

// Мобильное меню
mobileMenu({
  btnOpenTag: ".js-btn-mobile-menu-togle"
});

// accordTab
accordTab({
  rootTag: ".js-tabs",
  nameTag: ".js-tab-name",
  itemTag: ".js-tab-item",
  nameGroup: ".js-tab-name-group",
  scroll: true,
  accordTabSize: 1150
});

// мобильные слайдеры
slider({
  rootTag: ".js-mobile-slider",
  slideItemTag: ".js-inner > *",
  prev: ".js-prev",
  next: ".js-next",
  controls: "",
  interval: ""
});

// слайдер на главной
slider({
  rootTag: ".js-index-promo-slider",
  slideItemTag: ".js-inner > *",
  prev: ".js-prev",
  next: ".js-next",
  controls: ".js-controls > *",
  interval: "4000"
});

// минигалерея
miniGallery({
  rootTag: ".js-mini-gallery",
  bigPhotoTag: ".js-big-photo",
  itemTag: ".js-thumbs",
  data: "bigimage"
});

// раскрывающийся список
// сертификаты
expandListStep({
  rootTag: ".js-el-row",
  itemTag: ".js-el-item",
  numberDisplayItem: "3",
  readMoreTag: ".js-read-more",
  step: 30,
  textForOpen: "показать еще",
  textForClose: "скрыть",
  showNumberHidden: true,
  animationClass: "is-appear",
  noClose: false
});

// плавный скрол к якорям
autoScrollToTag({
  itemTag: ".js-scroll",
  duration: "50",
  speed: "2000",
  ofset: 30,
  parentTag: ".js-scroll-parent",
  noParentOfsetLessWhen: 1150
});

// Выпадающий список
dropList({
  rootTag: ".js-drop-menu",
  dropbox: ".js-drop-box"
});

// Автоформы
let autoformListNode = document.querySelectorAll(".js-form");
var autoformList = Array.prototype.slice.call(autoformListNode, 0);

autoformList.forEach(function(form) {
  // console.log(form);
  autoforms.init(form, {
    Validators: {
      maskphone: {
        keys: "1234567890",
        errorMessage: "Type only numbers",
        validatorFunction: function validatorFunction(field) {
          return (field.valid = field.nodeLink.value.indexOf("_") < 0);
        },
        keypressValidatorFunction: false
      }
    }
  });
});

// Обрабатываем телефон
let telListNode = document.querySelectorAll(".js-tel");
var telList = Array.prototype.slice.call(telListNode, 0);
telList.forEach(function(telInp) {
  maskedInput(telInp).mask("+7 (999) 999-99-99");
});

// отправка форм по аякс
forms(".js-form");

// авторазмеры больших картинок для галереи
// (function() {
//   let imgArr = document.querySelectorAll(".gallery-pswp__link");
//   imgArr = Array.from(imgArr);
//   let promiseAll = [];

//   imgArr.forEach(function(a, i){
//     let promise = new Promise((resolve, reject) => {
//       let link = a.href;
//       let img = new Image();
//       img.onload = function() {
//           let size = img.width + 'x' + img.height;
//           a.setAttribute('data-size', size);
//           resolve("load img " + i);
//       };  
//       img.src = link;
//     });
//     promise.then(
//       result => console.log(result),
//       error =>  console.log("картинка не загрузилась") 
//     );
//     promiseAll[i] = promise;
//   });

//   console.log(promiseAll); 

//   Promise.all(promiseAll).then(
//     results => {
//       // инициализация галереи (картинки и видео)
//       console.log("gallery init"); 
//       initPhotoSwipe(".js-pswp-gallery");
//     },
//     error =>  {
//       console.log("картинки не загрузилась")
//     } 
//   );

// })();

// инициализация галереи (картинки и видео)
  initPhotoSwipe(".js-pswp-gallery");

// Форматирование чисел в цене
// $(".js-price").each(function( index ) {
//   let str = $(this).text();
//   str = accounting.formatNumber(str);
//   $(this).text(str);
// });
// 

// фильтр расписания
liveFilter({
    rootTag: ".js-shedule",
    itemTag: ".js-shedule-item",
    categoryTag: ".js-shedule-category",
    controlFormTag: ".js-control-form",
    controlTag: ".js-control",
    delay: 0    
});

// другие фильтры
liveFilter({
    rootTag: ".js-filter",
    itemTag: ".js-filter-item",
    categoryTag: ".js-filter-category",
    controlFormTag: ".js-control-form",
    controlTag: ".js-control",
    delay: 0
});

// молния
lightning({
    rootTag: ".js-lightning",
    closeTag: ".js-lightning-close",
    closeClass: "is-lightning-close"
});

// search 
if (document.querySelector(".js-header-search")) {
    document.querySelector(".js-header-search").onclick = function(e) {
        document.body.classList.toggle("is-search-open");
    }
}

// читать еще группу новостей
readMoreGroup({
  rootTag: ".js-readMoreGroup",
  itemTag: ".js-readMoreGroup-item",
  moreBtnTag: ".js-readMoreGroup-btn",
  step: 12,
  stepIndicator: ".js-readMoreGroup-step",
  delay: 0,
  animationClass: "is-review-appear",
  scroll: true,
  ofset: 0,
  duration: 50,
  allOpen: false
});

// Только один чекбокс может быть выбран
onlyOneCheckboxSelected(".js-only-one-checkbox-selected");