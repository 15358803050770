module.exports = function (ymaps) {
      return new Promise(function (resolve, reject) {
            if (!!ymaps) {
                try {
                    ymaps.geolocation.get({
                        provider: "yandex",
                        autoReverseGeocode: true
                    }).then(function (result) {
                        let location = result.geoObjects.get(0).properties.get("metaDataProperty").GeocoderMetaData;
                        location.geo = result;
                        resolve(location);
                    }, function (err) {
                        reject(err);
                    });
                } catch (e) {
                    reject(e);
                }
            } else {
                reject(new Error("Gelocation works only with loaded ymaps. Usage: require('geolocation')(ymaps).then(...)"));
            }
        });
    };
