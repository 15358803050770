/*

  SimpleSlider 1.5

Простой слайдер с контролами, кнопками вперед/назад и таймером.

Действие: добавляет класс is-active к слайдам и контролам.

Принимает настройки:
  rootTag - корень слайдера
  slideItemTag - тег слайда
  prev: - кнопка назад
  next: - кнопка вперед
  controls: переключатели (радиобатоны)
  interval: интервал (в мс)

Подключение:
// слайдер
const slider = require("./simpleSlider");

// слайдер
slider({
  rootTag: ".js-index-promo-slider",
  slideItemTag: ".js-inner > *",
  prev: ".js-prev",
  next: ".js-next",
  controls: ".js-controls > *",
  interval: "4000"
});

 */

module.exports = function (option) {

    // проверка
    if (option.rootTag && option.slideItemTag) {
        if (document.querySelector(option.rootTag) && document.querySelector(option.slideItemTag)) {            
            runSliderList(option);
        } else {
            if (!document.querySelector(option.rootTag)) {
                // console.log("не найден корневой элемент " + option.rootTag);
            } else {
                if (!document.querySelector(option.slideItemTag)) {
                    console.error("не найдены элементы-слайды");
                }                    
            }        
        }
    } else {
        console.error("слайдеру не заданы необходимые параметры"); 
    }

    // Перебор слайдеров
    function runSliderList (option) {
        let rootListNode = document.querySelectorAll(option.rootTag);
        var rootList = Array.prototype.slice.call(rootListNode, 0);

        rootList.forEach (function (rootEl, i, arr) {
            option.rootEl = rootEl;
            runSlider(option);
        }); 
    }

    // запуск слайдера
    function runSlider (option) {
        let rootEl = option.rootEl;
        // console.log("Подключен слайдер " + option.rootTag); 
        var currentSlide = 0;        
        var slideItemTag = option.slideItemTag;
        var slidesNode = rootEl.querySelectorAll(option.slideItemTag);
        var slides = Array.prototype.slice.call(slidesNode, 0);
            
        // проверка на кнопки
        if (option.prev && option.next) {
            if (rootEl.querySelector(option.prev) && rootEl.querySelector(option.next) ) {
                var prevSlide = rootEl.querySelector(option.prev);
                var nextSlide = rootEl.querySelector(option.next); 

                nextSlide.onclick = function () {
                    goNext();
                }

                prevSlide.onclick = function () {
                    goPrev();
                }                
            }
        }

        // проверка на controls
        if(option.controls) {
            if (rootEl.querySelectorAll(option.controls).length >0 ) {
                var controls = rootEl.querySelectorAll(option.controls);
                controls = Array.from(controls);

                controls.forEach (function (item, i, arr) {
                    controls[i].onclick = function () {
                        currentSlide = i;
                        goSlide(currentSlide);                
                    }
                }); 

            }             
        }
 
        // проверка на интервал
        if (option.interval) {
            setInterval(function () {
                goNext();
            }, option.interval);
        } 

        // переключение на 1 слайд при старте
        if (slides.length > 0) {
            slides.forEach (function (item, i, arr) {
                item.classList.remove('is-active'); 
            });
            slides[0].classList.add('is-active');            
        }

        // основной код  
        let removeActive = function () {
            slides.forEach (function (item, i, arr) {
                item.classList.remove('is-active');
            });
            if (controls) {
                controls.forEach (function (item, i, arr) {
                    item.classList.remove('is-active');
                });
            } 
        }
        
        let goPrev = function () {
            if ((currentSlide-1)<0) {
                currentSlide = slides.length - 1;
            } else {
                currentSlide = (currentSlide - 1) % slides.length;
            }
            goSlide(currentSlide);
        } 

        let goNext = function () { 
            currentSlide = (currentSlide + 1) % slides.length;
            goSlide(currentSlide);
        }

        let goSlide = function (currentSlide) {
            removeActive ();
            slides[currentSlide].classList.add('is-active');

            if (controls) {
                controls[currentSlide].classList.add('is-active');
            }  
        }    


    }

};


